import KTooltip from '@/components/Tooltip'
import KCalculator from '@/components/Calculator'
import KNavbarDropdown from './NavbarDropdown'
import KResourcesDropdown from './ResourcesDropdown'
import KReportsDropdown from './ReportsDropdown'
import { mapActions, mapGetters } from 'vuex'
import { isLearning } from '../../utils/learning-platform'
import { FEATURE_FLAGS } from '@/constants'
import Five9WidgetHelpers from '@/utils/five9-widget-helpers'

export default {
  name: 'KNavbar',
  components: {
    KResourcesDropdown,
    KNavbarDropdown,
    KCalculator,
    KReportsDropdown,
    KTooltip
  },
  data: () => ({
    isMobileMenuOpen: false,
    showCalculator: false,
    isLearning,
    showLeads: false
  }),
  computed: {
    ...mapGetters({
      productTypeIsLoan: 'stores/getProductTypeIsLoan',
      merchant: 'auth/getUser'
    }),
    isLaunchDarklyInitialized() {
      return this.$store.getters['features/isInitialized']
    }
  },
  methods: {
    ...mapActions({
      perfomLogout: 'auth/logout'
    }),
    logout() {
      this.isMobileMenuOpen = false
      if (isLearning) {
        this.$router.push({ name: 'logout-learning' })
        return
      }

      this.perfomLogout()
      this.$router.push({ name: 'login' })
    }
  },
  watch: {
    isMobileMenuOpen(val) {
      if (!val) {
        Five9WidgetHelpers.addFive9Widget()
      } else {
        Five9WidgetHelpers.removeFive9Widget()
      }
    },
    isLaunchDarklyInitialized(val) {
      if (val) {
        this.showLeads = this.$store.getters['features/getFlag'](FEATURE_FLAGS.LEADS)
      }
    }
  }
}
