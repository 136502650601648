import Five9WidgetHelpers from '@/utils/five9-widget-helpers'

export default {
  name: 'KModal',
  props: {
    heading: {
      type: String,
      default: null
    },
    subHeading: {
      type: String,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    adjustWidth: {
      type: Boolean,
      default: false
    },
    iconBackground: {
      type: String,
      default: ''
    },
    persist: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Object,
      default: () => ({
        name: '',
        color: 'primary'
      }),
      required: false
    },
    customSvgSource: {
      type: String,
      required: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      if (window.Five9SocialWidget) {
        Five9WidgetHelpers.addFive9Widget()
      }
      this.$emit('close')
    },
    openFive9() {
      if (window.Five9SocialWidget) {
        Five9WidgetHelpers.addFive9Widget()
      }
    },
    closeFive9() {
      if (window.Five9SocialWidget) {
        Five9WidgetHelpers.removeFive9Widget()
      }
    }
  }
}
