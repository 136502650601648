/* global Five9SocialWidget */
const Five9WidgetHelpers = {
  addFive9Widget() {
    const options = {
      rootUrl: 'https://app.five9.com/consoles/',
      type: 'chat',
      title: 'Kafene',
      tenant: 'Kafene',
      profiles: 'English,Spanish',
      showProfiles: true,
      autostart: true,
      profileLabel: 'Language',
      theme: 'default-theme.css',
      logo: 'https://kafene-brand-logos.s3.us-west-2.amazonaws.com/logo_small_no_background.png',
      surveyOptions: {
        showComment: true,
        requireComment: false
      },
      fields: {
        name: {
          value: '',
          show: true,
          label: 'Name'
        },
        email: {
          value: '',
          show: false,
          label: 'Email'
        },
        'Chat.ApplicationNumber': {
          value: '',
          show: true,
          label: 'Application Number ',
          required: false
        },
        UserLocale: {
          value: 'en',
          show: false
        }
      },
      playSoundOnMessage: true,
      allowCustomerToControlSoundPlay: false,
      showEmailButton: false,
      hideDuringAfterHours: false,
      useBusinessHours: true,
      showPrintButton: false,
      allowUsabilityMenu: true,
      enableCallback: false,
      callbackList: '',
      allowRequestLiveAgent: false
    }

    if (window.Five9SocialWidget) {
      Five9SocialWidget.addWidget(options)
    } else {
      console.error('Five9SocialWidget is not available')
    }
  },

  removeFive9Widget() {
    if (window.Five9SocialWidget) {
      Five9SocialWidget.removeWidget()
    } else {
      console.error('Five9SocialWidget is not available')
    }
  }
}

export default Five9WidgetHelpers
